<script setup>
import {
    ref, onMounted, computed, watch,
} from 'vue';
// import Tempus from '@studio-freight/tempus';
import vocaLogo from '@/assets/svg/voca-logo.svg';
import vocatioLogo from '@/assets/svg/vocatio-logo.svg';
import Cursor from '@/components/cursor.vue';
// import BlurCircle from '@/components/blur-circle.vue';
import { useCursor } from '@/composables/use-cursor.js';
import useStore from '@/composables/store.js';
import { useRoute } from 'vue-router';
import { useLenis } from '@/composables/lenis.js';
import { gsap, ScrollTrigger } from '@/composables/gsap.js';
import setOrigin from '@/composables/set-origin.js';
// import getClient from '@/composables/graphql-client.js';
import urlToUri from '@/composables/urlToUri.js';
import getClient from '@/composables/graphql-client.js';
import { useQuery } from 'villus';
import appQuery from '@/queries/app.gql';

const store = useStore();
const menuOpened = ref(false);
const cursorHandler = useCursor();
const isLoading = ref(false);
const isInitialLoad = ref(true);
const isLoadingTimeoutElapsed = ref(false);
const currentRoute = useRoute();
const lenis = useLenis();
const { CRAFT_SITES } = window;
const { projectby, openmenu } = window.CRAFT_LABELS;

getClient();

const currentUri = computed(() => {
    // currentUri with baseUrl replacement cannot work since it's not reactive
    // eslint-disable-next-line no-unused-vars
    const theRoute = currentRoute.path;
    return window.location.href.replace(store.site.value.baseUrl, '');
});

const variables = computed(() => ({
    slug: !currentUri.value ? 'home' : null,
    uri: currentUri.value === '' ? null : currentUri.value,
    lang: store.lang.value,
}));

const { isTransitioning } = store;

const { data } = useQuery({
    query: appQuery,
    variables,
});

watch(data, () => {
    store.currentEntry.value = data.value.entry;
});

const checkLoadingReset = () => {
    if (isLoadingTimeoutElapsed.value && !isLoading.value) {
        cursorHandler.value.disableAll();
        cursorHandler.value.enableDefault();
        // cursorHandler.value.checkShadowHover();
    }
};

const enterLoading = () => {
    lenis.value.stop();
    menuOpened.value = false;

    if (!isInitialLoad.value && cursorHandler.value) {
        isLoading.value = true;
        isLoadingTimeoutElapsed.value = false;
        cursorHandler.value.enableCursor('loading');

        // Minimal duration
        window.setTimeout(() => {
            isLoadingTimeoutElapsed.value = true;
            checkLoadingReset();
        }, 3000);
    } else {
        isInitialLoad.value = false;
    }
};
const resolveLoading = () => {
    isLoading.value = false;
    checkLoadingReset();
};

const onBeforeEnter = () => {
    lenis.value.start();
};

const onEnter = (el, done) => {
    if (store.scrollPositions.value[currentRoute.path]) {
        lenis.value.scrollTo(store.scrollPositions.value[currentRoute.path], { immediate: true });
    } else {
        lenis.value.scrollTo(0, { immediate: true });
    }
    gsap.fromTo(el, {
        opacity: 0,
    }, {
        opacity: 1,
        duration: 1,
        onComplete() {
            // eslint-disable-next-line no-param-reassign
            el.style = '';
        },
    });
    gsap.to('.loader-circle', {
        y: () => Math.random() * 1200 - 600,
        x: () => Math.random() * 1200 - 600,
        opacity: 0,
        stagger: {
            amount: 1,
        },
        onComplete() {
            done();
        },
        ease: 'power1.inOut',
    });
};
const onAfterEnter = () => {
    ScrollTrigger.refresh();
    isTransitioning.value = false;
};
const onBeforeLeave = () => {
    isTransitioning.value = true;
    gsap.set('.loader-circle', { clearProps: 'all' });
};
const onLeave = (el, done) => {
    gsap.fromTo(el, {
        opacity: 1,
    }, {
        opacity: 0,
        duration: 1,
    });
    gsap.from('.loader-circle', {
        y: () => Math.random() * 1200 - 600,
        x: () => Math.random() * 1200 - 600,
        opacity: 0,
        stagger: {
            amount: 1,
        },
        onComplete() {
            done();
        },
        ease: 'power1.inOut',
    });
};
const onAfterLeave = () => {
};

const goToSite = (ev) => {
    window.location = ev.currentTarget.value;
};

onMounted(() => {
    ScrollTrigger.refresh();

    // window.addEventListener('wheel', () => {
    //     console.log('wheel', document.documentElement.getAttribute('class'));
    // });
    // window.addEventListener('scroll', () => {
    //     console.log('scroll', document.documentElement.getAttribute('class'));
    // });
    // Tempus.add((time) => {
    //     console.log('Tempus', document.documentElement.getAttribute('class'));
    // }, 10000);
});
</script>
<template>
    <nav
        class="fixed left-4 top-0 z-30 flex h-[calc(100vh-1rem)] w-[calc(100vw-2rem)] flex-col rounded-b-3xl bg-gradient-to-bl from-peach to-turquoise px-8 py-4 transition duration-1000 lg:hidden"
        :class="{
            'translate-x-0': menuOpened,
            'translate-x-[100vw]': !menuOpened,
        }"
    >
        <div v-if="data && data.entry" class="languages flex gap-4">
            <router-link
                class="font-heading text-4xl font-bold uppercase"
                :to="urlToUri(data.entry.url)"
                :class="{'border-b-4 border-black': store.site.handle == data.entry.siteHandle}"
            >
                {{ data.entry.siteHandle }}
            </router-link>
            <router-link
                v-for="site in data.entry.localized"
                :key="site.siteHandle"
                class="font-heading text-4xl font-bold uppercase"
                :to="urlToUri(site.url)"
                :class="{
                    'border-b-4 border-black': store.site.handle == site.siteHandle
                }"
            >
                {{ site.siteHandle }}
            </router-link>
        </div>

        <ul v-if="data && data.menuMain" class="pages mt-16 list-none">
            <li
                v-for="link in data.menuMain"
                :key="link.title + 'overlay'"
            >
                <router-link
                    class="font-heading text-5xl uppercase text-white"
                    :to="urlToUri(link.url)"
                >
                    {{ link.title }}
                </router-link>
            </li>
        </ul>

        <ul v-if="data && data.menuCredential" class="pages-credits mb-4 mt-auto list-none">
            <li
                v-for="link in data.menuCredential"
                :key="link.title + 'overlay'"
            >
                <a
                    :class="link.classes"
                    :href="link.url"
                    class="ml-2 inline-block border-gray px-2 pl-0 underline first:ml-0 first:border-r"
                >
                    {{ link.title }}
                </a>
            </li>
        </ul>
    </nav>
    <transition
        enter-active-class="duration-1000 transition-[opacity,transform] ease-out"
        enter-from-class="opacity-0 "
        enter-to-class="opacity-100"
        leave-active-class="duration-1000 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="menuOpened"
            class="underlay fixed left-0 top-0 z-20 h-screen w-screen bg-black/70"
        />
    </transition>
    <div id="wrapper" class="bg-black">
        <!-- <blur-circle
            :min-radius="1200"
            :max-radius="1200"
            :nombre-ronds="2"
            :speed=".008"
            :colors="['247, 186, 153']"
            :allow-overflow-x="true"
            :allow-overflow-y="true"
            :fixed="true"
        /> -->
        <header class="fixed top-0 z-50 w-full">
            <button
                class="absolute right-8 top-8 h-12 w-18 lg:hidden"
                v-on:click.stop="menuOpened = !menuOpened"
            >
                <div class="sr-only">
                    {{ openmenu }}
                </div>
                <div
                    aria-hidden
                    class="absolute top-0 h-2 w-18 transition"
                    :class="{
                        'translate-y-2 rotate-45 bg-black': menuOpened,
                        'bg-white': !menuOpened,
                    }"
                />
                <div
                    aria-hidden
                    class="absolute top-0 h-2 w-18 translate-y-4 transition"
                    :class="{
                        'bg-black opacity-0': menuOpened,
                        'bg-white opacity-100': !menuOpened,
                    }"
                />
                <div
                    aria-hidden
                    class="absolute top-0 h-2 w-18 transition"
                    :class="{
                        'translate-y-2 -rotate-45 bg-black': menuOpened,
                        'translate-y-8 bg-white': !menuOpened,
                    }"
                />
            </button>
            <div v-if="CRAFT_SITES && data && data.entry" class="absolute right-8 top-8 hidden lg:right-12 lg:block lg:translate-x-1/2">
                <select class="w-full appearance-none bg-transparent pr-5 uppercase text-white" v-on:change="goToSite($event)">
                    <option :selected="store.site.handle == data.entry.siteHandle" :value="data.entry.url">
                        {{ data.entry.siteHandle }}
                    </option>
                    <option
                        v-for="site in data.entry.localized"
                        :key="site.siteHandle"
                        :value="site.url"
                        :selected="store.site.handle == site.siteHandle"
                    >
                        {{ site.siteHandle }}
                    </option>
                </select>
                <div class="absolute right-0 top-1/2 -translate-y-2/4">
                    <svg
                        width="16"
                        height="10"
                        viewBox="0 0 16 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1C3.73367 3.73367 8 8 8 8L15 1"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                        />
                    </svg>
                </div>
            </div>
            <div v-if="data" class="hidden lg:block">
                <transition-group
                    enter-active-class="duration-2000 transition-[opacity,transform] delay-variable ease-out"
                    enter-from-class="opacity-0 -translate-y-12"
                    enter-to-class="opacity-100"
                    leave-active-class="duration-2000 transition-[opacity,transform] ease-in"
                    leave-from-class="opacity-100 "
                    leave-to-class="opacity-0"
                    appear
                >
                    <div
                        v-for="link in data.menuMain"
                        :key="link.title"
                        class="absolute top-1/2vh -rotate-90 first-of-type:left-0 first-of-type:ml-2.5 last-of-type:right-0"
                        :style="{ '--tw-transition-delay': `${1 * 150}ms` }"
                    >
                        <a
                            data-cursor="circle-white"
                            data-cursor-adjust-width="true"
                            data-cursor-adjust-height="true"
                            class="btn-gradient"
                            :href="link.url"
                            v-on:mouseenter="setOrigin($event, -90)"
                            v-on:mouseleave="setOrigin($event, -90)"
                        >
                            <span class="btn-text text-base xl:text-lg">{{ link.title }}</span>
                            <span class="background-fill" aria-hidden />
                        </a>
                    </div>
                </transition-group>
            </div>
        </header>
        <div class="router-view-wrapper min-h-screen">
            <router-view v-slot="{ Component, route }">
                <transition
                    mode="out-in"
                    :css="false"
                    v-on:before-enter="onBeforeEnter"
                    v-on:enter="onEnter"
                    v-on:after-enter="onAfterEnter"
                    v-on:before-leave="onBeforeLeave"
                    v-on:leave="onLeave"
                    v-on:after-leave="onAfterLeave"
                >
                    <suspense v-on:pending="enterLoading" v-on:resolve="resolveLoading">
                        <component :is="Component" :key="route.path" />
                    </suspense>
                </transition>
            </router-view>
        </div>
        <footer class="bg-black px-8 pb-44 pt-14 text-white lg:px-24 lg:py-64">
            <div class="container mx-auto">
                <div class="row flex-row items-center justify-between lg:flex">
                    <div class="items-center lg:flex">
                        <div class="logo mb-16 mr-10 lg:mb-0">
                            <voca-logo />
                        </div>
                        <div
                            v-if="data"
                            class="mb-5 text-3xl lg:mb-0"
                        >
                            <a
                                v-for="link in data.menuFooter"
                                :key="link.id"
                                :class="link.classes"
                                :href="link.url"
                                class="mb-5 block border-white lg:inline-block lg:pl-5 first-of-type:lg:mb-0 first-of-type:lg:border-r first-of-type:lg:pl-0 first-of-type:lg:pr-5"
                            >
                                {{ link.title }}
                            </a>
                        </div>
                    </div>
                    <div class="order-1 mb-6 items-center text-lg lg:order-2 lg:mb-0 lg:flex">
                        {{ projectby }}
                        <a
                            href="http://www.citedesmetiers.ca/"
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            class="mt-4 block lg:ml-10 lg:mt-0"
                        >
                            <vocatioLogo class="fill-current text-white" />
                        </a>
                    </div>
                </div>

                <div class="row flex flex-col pt-16 lg:flex-row lg:items-start lg:justify-between lg:pt-44">
                    <div
                        v-if="data && data.footer && data.footer.image"
                        class="shrink-0 text-sm text-gray lg:text-lg"
                    >
                        <img
                            v-for="(logo) in data.footer.image"
                            :key="logo.id"
                            :alt="logo.alt"
                            :src="logo.url"
                            :srcset="logo.srcset"
                            class="mb-8 block h-auto max-w-full lg:w-auto lg:[&:nth-child(1)]:h-[70px] lg:[&:nth-child(2)]:h-[132px]"
                        >
                    </div>
                    <div class="flex flex-col justify-between lg:flex-row lg:flex-wrap">
                        <div v-if="data && data.menuCredential" class="order-2 mt-8 w-full text-right text-sm text-gray lg:order-1 lg:text-lg">
                            <a
                                v-for="link in data.menuCredential"
                                :key="link.id"
                                :class="link.classes"
                                :href="link.url"
                                class="ml-2 inline-block border-r border-gray px-2 pl-0 underline first:ml-0 last:border-0 last:pr-0"
                            >
                                {{ link.title }}
                            </a>
                        </div>
                        <div
                            v-if="data"
                            class="items order-1 mt-8 flex w-full flex-wrap justify-center gap-6 lg:order-2 lg:justify-end lg:gap-14"
                        >
                            <a
                                v-for="link in data.footer.socialsMedias"
                                :key="link.id"
                                :href="link.weblink"
                                class="inline-block fill-current text-white svg:h-4 svg:w-auto lg:block lg:svg:h-6 xl:svg:h-8"
                                target="_blank"
                                v-html="link.icon.inline"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    <cursor />
    <div v-show="isTransitioning" class="loader-circles fixed left-0 top-0 z-50 h-full w-full">
        <!-- <div class="loader-circles fixed left-0 top-0 z-50 h-full w-full"> -->
        <div class="loader-circle loader-circle-1 absolute left-[-100vw] top-[-50vw] z-10 h-[200vw] w-[200vw] bg-radial-peach" />
        <div class="loader-circle loader-circle-2 absolute left-[-50vw] top-[-100vw] z-10 h-[150vw] w-[150vw] bg-radial-turquoise" />
        <div class="loader-circle loader-circle-2 absolute left-[-50vw] top-[-100vw] z-10 h-[150vw] w-[150vw] bg-radial-turquoise" />
        <div class="loader-circle loader-circle-1 absolute left-[25vw] top-[-150vw] z-10 h-[200vw] w-[200vw] bg-radial-peach" />
        <div class="loader-circle loader-circle-12 absolute left-[-2vw] top-[9vw] z-10 h-[100vw] w-[100vw] bg-radial-turquoise" />
        <div class="loader-circle loader-circle-8 absolute left-[37vw] top-[-33vw] z-10 h-[125vw] w-[125vw] bg-radial-peach" />
    </div>
</template>
